import img_instagram from './img/icon_inst.png';
import img_twitter from './img/icon_twitter.png';
import img_header_main from './img/main.jpg';
import './App.css';


function App() {
    //自動でnoopener作成
  (function() {
    var aTags = [].slice.call(document.getElementsByTagName('a'));
    var ua = window.navigator.userAgent.toLowerCase();
    var isIE = (~ua.indexOf('msie') || ~ua.indexOf('trident'));
    if (!isIE) {
      aTags.forEach(function(el) {
        if (el.target === '_blank'){
          var rels = el.rel.split(' ');
          if (!~rels.indexOf('noopener')) {
            rels.push('noopener');
            el.setAttribute('rel', rels.join(' ').trim());
          }
          if (!~rels.indexOf('noreferrer')) {
            rels.push('noreferrer');
            el.setAttribute('rel', rels.join(' ').trim());
          }
        }
      });
    }
  })();
  return (
    <div className="App">
      <header>
        <Header />
        <Header_ani />
        <Header_menu />
        <Header_under />
        <Main />
      </header>
    </div>
  );
}

function Main() {
  let red='#F81414';
  let blue='#030E7C';
  let green='#36A008';
  let pink = '#E312A4';
  return (
    <div id="Main">
        <ul className="background">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>


        <h1 className="logo_font" id="about">ABOUT</h1>
        <div className="container999" style={{fontSize:20}}><p>2023/1/31～　工事中!</p></div>
        <p>MYRTILLESの公式サイトです。（Twitter <a href="https://twitter.com/shoh_frf" target="_blank">@shoh_frf</a>）<br/>
        お問い合わせは<a href="https://twitter.com/shoh_frf" style={{color:blue}}>ツイッターのDM</a>・<a href="index.php#contact" style={{color:red}}>"お問い合わせフォーム"</a>または
        <inline style={{color:green}}>"myrtilles@hotmail.com"</inline>にてお気軽にご連絡ください。
        </p>
        <hr/>
        <div className="wrap">
          <div className="inner">
            <h3 className="pc_dis">PROFILE</h3>
            <h2 className="mb_dis">PROFILE</h2>
              <strong style={{color:pink}}>趣味など</strong>#ピアノ#VTuber#PC#ヨーロッパ#スマホ講師資格保持#タロット#写真#サックス#FIRE実行#
              <br/><strong style={{color:pink}}>アニメ&ゲーム</strong>#プリマジ#プリパラ#転天#カッコウの許嫁#プリコネ#キラパラ

          </div>
          <div className="inner pc_dis">
            <a style={{color:blue}}>created by myrtilles</a>
          </div>
        </div>
    </div>
  );
}

/*
<section id="san_box">
          <section id="san_box2_url"><a href="https://www.instagram.com/shoh.frf" target="_blank"><img src={img_instagram} alt="instagram"/></a></section>
          <section id="san_box3_url"><a href="https://twitter.com/shoh_frf" target="_blank"><img src={img_twitter} alt="twitter"/></a></section>
        </section> 
              <div className="loop_wrap">
        <div>ただいまメンテナンス中　ただいまメンテナンス中　ただいまメンテナンス中　ただいまメンテナンス中　</div><div>ただいまメンテナンス中　ただいまメンテナンス中　ただいまメンテナンス中　ただいまメンテナンス中　</div>
      </div>*/
function Header() {
  return (
    <div className="Header">
        
        <section id="san_box_hider_box">
          <section id="logo_font_top">MYRTILLES</section>
        </section>
        
        <section id="contact_box">
          <input id="popup_open" type="checkbox"/><label for="popup_open" className="logo_font_top_under">CONTACT</label>
          <label for="popup_open" className="overlay"></label>
          <div className="popup_content">
              <label for="popup_open" className="popup_close_btn"><i className="batu"></i></label>

              <section className="bgcolor3">
                <div className="container">
                  <h2 id="contact">CONTACT</h2>
                  <p className="center">お問い合わせ</p>
                  <form method="post" action="http://myrtilles.official.jp/mail.php" className="mailform">
                  <input type="text" name="name" class="input1 tdm" placeholder="お名前(ペンネーム)"/>
                  <input type="mail" name="address" class="input1 tdm" placeholder="メールアドレス"/>
                  <section id="form_label">
                    <label><input type="radio" name="why[]" value="お問い合わせ"/>お問い合わせ</label>
                    <label><input type="radio" name="why[]" value="バグ報告"/>バグ報告</label>
                    <label><input type="radio" name="why[]" value="要望"/>要望</label>
                  </section>
                  <textarea rows="5" name="message" class="tdm" placeholder="本文"></textarea>
                  <input type="submit" value="Send"/>
                  </form>
                </div>
              </section>
          </div>
        </section>
    </div>
  );
}

function Header_ani() {
  return (
    <div className="Header_ani">
      <section id="boader_box">
        <section className="border"></section><section className="border"></section><section className="border"></section><section className="border"></section><section className="border"></section><section className="border"></section>
      </section>
    </div>
  );
}

function Header_menu() {
  return (
    <div id="Header_menu">
      <ul id="top_menu">
        <li><a href="#"><span><b>メニュー1</b><small>MENU 1</small></span></a></li>
        <li><a href="#"><span><b>メニュー2</b><small>MENU 2</small></span></a></li>
        <li><a href="#"><span><b>メニュー3</b><small>MENU 3</small></span></a></li>
      </ul>
    </div>
  );
}

function Header_under() {
  return (
    <div id="Header_under">

        <img src={img_header_main} className="pc_dis" oncontextmenu="return false;" />
        <img src={img_header_main} className="mb_dis" oncontextmenu="return false;" />

      <div className="loop_wrap loop_wrap2">
        <div>We are just getting ready. We are just getting ready. We are just getting ready. We are just getting ready. </div><div>We are just getting ready. We are just getting ready. We are just getting ready. We are just getting ready. </div>
      </div>
    </div>
  );
}


export default App;
